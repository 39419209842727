@if (hasLink()) {
  <a
    class="inline-block"
    [style.height]="logoDimensions().height"
    [style.width]="logoDimensions().width"
    [href]="linkService.localizedPathTo(ArticlePage, {routeParams: {pageSlug: 'partner-mobiliar'}})"
    target="_blank"
  >
    <ng-container *ngTemplateOutlet="bgoLMobiliarLogo"></ng-container>
  </a>
}

@if (!hasLink()) {
  <div class="inline-block" [style.height]="logoDimensions().height" [style.width]="logoDimensions().width">
    <ng-container *ngTemplateOutlet="bgoLMobiliarLogo"></ng-container>
  </div>
}

<ng-template #bgoLMobiliarLogo>
  @if (!negative()) {
    @if (translocoService.getActiveLang() === Language.GERMAN) {
      <img
        src="assets/images/mobiliar/mobiliar-de.svg"
        alt="die Mobiliar"
        [width]="logoDimensions().width"
        [height]="logoDimensions().height"
      />
    } @else {
      <img
        src="assets/images/mobiliar/mobiliar-fr.svg"
        alt="la Mobilière"
        [width]="logoDimensions().width"
        [height]="logoDimensions().height"
      />
    }
  }

  @if (negative()) {
    @if (translocoService.getActiveLang() === Language.GERMAN) {
      <img
        src="assets/images/mobiliar/mobiliar-de-neg.svg"
        alt="die Mobiliar"
        [width]="logoDimensions().width"
        [height]="logoDimensions().height"
      />
    } @else {
      <img
        src="assets/images/mobiliar/mobiliar-fr-neg.svg"
        alt="la Mobilière"
        [width]="logoDimensions().width"
        [height]="logoDimensions().height"
      />
    }
  }
</ng-template>

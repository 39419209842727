import {booleanAttribute, ChangeDetectionStrategy, Component, computed, input} from '@angular/core'
import {TranslocoService} from '@ngneat/transloco'
import {Language} from '../../../../core/i18n/language'
import {LinkService} from '../../../../core/i18n/link.service'
import {ApplicationRoute} from '../../../../craft/utils/application.route'
import {PUBLIC_ROUTES} from '../../../routing/public.routes'

@Component({
  selector: 'bgo-mobiliar-logo',
  templateUrl: './mobiliar-logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobiliarLogoComponent {
  readonly Language = Language
  readonly ArticlePage: ApplicationRoute = PUBLIC_ROUTES.ARTICLE_PAGE

  size = input<LogoSize>('sm')
  logoDimensions = computed(() => this.calculateLogoDimensions(this.size()))
  negative = input(false)
  hasLink = input(true, {transform: booleanAttribute})

  protected readonly logoAspectRatio = 6.5

  constructor(
    readonly translocoService: TranslocoService,
    readonly linkService: LinkService,
  ) {}

  protected calculateLogoDimensions(size: LogoSize) {
    const width = (() => {
      switch (size) {
        case 'sm':
          return 100
        case 'xs':
          return 80
        case '2xs':
          return 72
        case '3xs':
          return 52
      }
    })()
    return {width, height: width / this.logoAspectRatio}
  }
}

export const logoSizes = ['sm', 'xs', '2xs', '3xs'] as const
export type LogoSize = (typeof logoSizes)[number]

import {NgModule} from '@angular/core'
import {CommonModule, NgOptimizedImage} from '@angular/common'
import {TranslocoModule} from '@ngneat/transloco'
import {RouterModule} from '@angular/router'
import {DirectivesModule} from '../../directives/directives.module'
import {MobiliarLogoComponent} from './mobiliar-logo/mobiliar-logo.component'
import {BuildigoAvatarComponent} from './buildigo-avatar/buildigo-avatar.component'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'

@NgModule({
  declarations: [MobiliarLogoComponent, BuildigoAvatarComponent],
  imports: [CommonModule, TranslocoModule, RouterModule, DirectivesModule, NgOptimizedImage, FontAwesomeModule],
  exports: [MobiliarLogoComponent, BuildigoAvatarComponent],
})
export class BrandingModule {}

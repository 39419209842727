import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {AvatarSize} from '../../../../craft/avatar/avatar.component'

@Component({
  selector: 'bgo-buildigo-avatar',
  templateUrl: './buildigo-avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        @apply block flex items-center justify-center rounded-full bg-president-500 text-center text-president-50 text-white;
      }
    `,
  ],
})
export class BuildigoAvatarComponent {
  @Input() protected size: AvatarSize = 'xs'
}
